import React, { Fragment } from 'react';
import ScriptLoader from 'react-render-props-script-loader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button, Tooltip, Modal, Radio, Tag } from 'antd';
import moment from 'moment';
import I18n from '../../i18n';
import { I18n as Translater } from 'react-redux-i18n';
import LoadingOverlay from 'react-loading-overlay';
import { withLayout } from '../../common/layout';
import { UserInput } from '../../global/user-input';
import { USER_INPUT_TYPES, DEFAULT_ZERO_VALUE, STRING_EMPTY } from '../../assets/constants/global';
import getSymbolFromCurrency from 'currency-symbol-map';
import { PROP_KEYS, PAYMENT_METHODS, DISCOUNT_TYPE, ADDITIONAL_OPTION_TYPE } from './home-constants';
import { homeActions } from './home-reducer';
import { PaymentRadio } from '../../global/payment-radio';
import applePay from '../../assets/icons/apple_pay.png';
import googlePay from '../../assets/icons/google_pay.png';
import payPal from '../../assets/icons/paypal.png';
import { Invoice } from '../../global/invoice-form';
import { homeService } from './home-service';
import { PayPalButton } from "react-paypal-button-v2";
import { config } from '../../config';
import Cookies from 'js-cookie';
import * as Scroll from 'react-scroll';

const { Group } = Radio;
const { Element, scroller, animateScroll } = Scroll;

class HomeCheckoutMobile extends React.Component {
    
    getUserEmailFromCoockies = () => {
        return  Cookies.get('user') && (JSON.parse(Cookies.get('user')).email);
    }

    getCompanyAccountRoleFromCoockies = () => {
        return Cookies.get('user') && (JSON.parse(Cookies.get('user')).role[0] === 'CompanyAccount');
    }

    calculateTotal = () => {
        const { addedOptions, selectedCar, returnTransfer } = this.props;

        return homeService.calculateTotal(addedOptions, selectedCar, returnTransfer);
    }

    calculateDiscount = () => {
        const { selectedCar, returnTransfer, promoDiscount: { typeOfRule, value } } = this.props;

        return homeService.calculateDiscount(selectedCar, value, typeOfRule, returnTransfer);
    }

    togglePromoModal = () => {
        const { setShowPromo, showPromo } = this.props;

        setShowPromo(!showPromo);
    }

    setPromo = (value) => {
        const { setPromo } = this.props;

        setPromo(value);
    }

    setPromocode = () => {
        const { setPromocode, checkPromocode, promo } = this.props;

        checkPromocode(promo);
        setPromocode(promo);
        this.togglePromoModal();
    }

    scrollToElement = (elementName, elementName2) => {
        const element = scroller.get(elementName);
        const element2 = scroller.get(elementName2);
        const ride_details_page = scroller.get("ride_details_page");
        const results_page = scroller.get("results_page");
        let height = DEFAULT_ZERO_VALUE;

        if (ride_details_page && results_page) {
            height += ride_details_page.clientHeight && results_page.clientHeight && ride_details_page.clientHeight + results_page.clientHeight;
        }
        
        if (element && element.clientHeight) {
            height += element.clientHeight;

            if (element2 && element2.clientHeight) {
                height += element2.clientHeight;
            }

            animateScroll.scrollTo(height);
        }
    }

    beginCheckout = (edit) => {
        const { beginCheckout } = this.props;
        const userEmailFromCoockies = this.getUserEmailFromCoockies();
        beginCheckout(edit, userEmailFromCoockies);
    }

    render() {
        const {
            isOpenedCheckout, selectedCar, isLoadedCheckout, promo, edit, paymentMethod,
            setEmail, setPhone, setFullName, userEmail, userPhone, oldTotal, removeVipOption,
            userFullName, setComment, userComment, addedOptions, addOption,
            removeOption, pickupLocationString, pickupLocationDatetime,
            destinationString, destinationDatetime, paymentMethodToggle, setPaymentMethod,
            distance, duration, showPromo, paypalCheckout, paypalCheckoutSessionCompleted, promoDiscount,
            paypalFailStatus, invoicePayment, returnTransfer, editCheckoutPaypal,
            pickupLocationIsAirport, dropoffLocationIsAirport, flightNumber, returnFlightNumber,
            setFlightNumber, setReturnFlightNumber, invoiceEmail, setInvoiceEmail, invoicePassword, setInvoicePassword
        } = this.props;
        const columns = [{
            key: 'name',
            dataIndex: 'name',
            width: '60%',
            render: (_text, { name, description, type }) => (
                <div className="d-flex flex-column additional-option">
                    <span className="name">{name} {type === ADDITIONAL_OPTION_TYPE.VIP && <Tag color="purple">VIP</Tag>}</span>
                    <div className="description">{description}</div>
                </div>
            )
        }, {
            key: 'price',
            dataIndex: 'price',
            width: '15%',
            render: (_text, { price }) => {
                const priceValue = price ? `${getSymbolFromCurrency(selectedCar.currency)} ${price}` : Translater.t('freeTitle');

                return (<span className='price'>{priceValue}</span>);
            }
        }, {
            key: 'actions',
            dataIndex: 'actions',
            width: '25%',
            render: (_text, { name, countable, maxCount, variants, type }) => {
                const added = addedOptions[name];

                return added ? (
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='added'><I18n t="addedTitle" /></span>
                        {
                            variants && variants.length > DEFAULT_ZERO_VALUE ? (
                                <Button onClick={() => removeOption(name)} className='actions-button light'><I18n t="removeTitle" /></Button>
                            ) : type === ADDITIONAL_OPTION_TYPE.VIP ? (
                                <Button onClick={() => removeVipOption(name)} className='actions-button light'><I18n t="removeTitle" /></Button>
                            ) : countable ? (
                                <div className='d-flex align-items-center actions-button light'>
                                    {
                                        !added || added === DEFAULT_ZERO_VALUE ? (
                                            <Tooltip trigger={[ 'hover', 'click' ]} className='disabled' title={Translater.t('minCountMsg')}>-</Tooltip>
                                        ) : (<span onClick={() => removeOption(name)}>-</span>)
                                    }
                                    {added || DEFAULT_ZERO_VALUE}
                                    { 
                                        maxCount === added ? (
                                            <Tooltip trigger={[ 'hover', 'click' ]} className='disabled' title={Translater.t('maxCountMsg')}>+</Tooltip>
                                        ) : (<span onClick={() => addOption(name)}>+</span>)
                                    }
                                </div>
                            ) : (
                                <Button onClick={() => removeOption(name)} className='actions-button light'><I18n t="removeTitle" /></Button>
                            )
                        }
                    </div>
                ) : (
                    <div className='d-flex justify-content-end'>
                        <Button className='actions-button' onClick={() => addOption(name)}>{countable ? Translater.t('addTitle') : Translater.t('getTitle')}</Button>
                    </div>
                );
            }
        }];
        const checkCompanyAccountRole = this.getCompanyAccountRoleFromCoockies();
        const totalPayment = this.calculateTotal();
        const discount = promoDiscount && this.calculateDiscount();
        const paymentAvailable = (Cookies.get('token') && !checkCompanyAccountRole) || (userEmail && userFullName && userPhone);
        const userEmailFromCoockies = this.getUserEmailFromCoockies();

        return (
            <Fragment>
                {
                    isOpenedCheckout && (
                        <LoadingOverlay className="checkout w-100" active={!isLoadedCheckout} spinner>
                            <ScriptLoader type='text/javascript'
                                src={`https://www.paypal.com/sdk/js?client-id=${config.paypalKey}&currency=${selectedCar.currency}`}
                                onError={error => console.error('failed to load paypal:', error.stack)}>
                                {_ => {
                                    return (
                                        <Fragment>
                                            <Element name={`checkout_mobile`}>
                                                <div className="d-flex checkout-title"><I18n t="pageTitles.checkout" /></div>
                                                <div className="d-flex flex-column">
                                                    <Element name={`details_and_summary`}>
                                                        <div className="d-flex flex-column mt-3 ml-3 mr-3 details-and-summary">
                                                            <span className='title'>{Translater.t('detailsAndSummaryTitle')}</span>
                                                            <div className="label-description">
                                                                <span className="label">{Translater.t('pickupLocationTitle')}</span>
                                                                <span>{pickupLocationString}</span>
                                                            </div>
                                                            <div className="label-description">
                                                                <span className="label">{Translater.t('destinationTitle')}</span>
                                                                <span>{destinationString}</span>
                                                            </div>
                                                            <div className="label-description">
                                                                <span className="label">{Translater.t('distanceTitle')}</span>
                                                                <span>{`${distance} - ${duration}`}</span>
                                                            </div>
                                                            <div className="d-flex w-100">
                                                                <div className="label-description col">
                                                                    <span className="label">{Translater.t('dateTitle')}</span>
                                                                    <span>{moment(pickupLocationDatetime).format('DD MMM')}</span>
                                                                </div>
                                                                <div className="label-description col">
                                                                    <span className="label">{Translater.t('timeTitle')}</span>
                                                                    <span>{moment(pickupLocationDatetime).format('HH:mm')}</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                returnTransfer && (
                                                                    <div className="d-flex w-100">
                                                                        <div className="label-description col">
                                                                            <span className="label">{Translater.t('returnDateTitle')}</span>
                                                                            <span>{moment(destinationDatetime).format('DD MMM')}</span>
                                                                        </div>
                                                                        <div className="label-description col">
                                                                            <span className="label">{Translater.t('returnTimeTitle')}</span>
                                                                            <span>{moment(destinationDatetime).format('HH:mm')}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="d-flex w-100">
                                                                <div className="label-description col">
                                                                    <span className="label">{Translater.t('carTitle')}</span>
                                                                    <span>{selectedCar.title}</span>
                                                                </div>
                                                                <div className="label-description col">
                                                                    <span className="label">{Translater.t('classTitle')}</span>
                                                                    <span>{selectedCar.type}</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex w-100">
                                                                <div className="label-description col">
                                                                    <span className="label">{Translater.t('passengersTitle')}</span>
                                                                    <span>{`${Translater.t('maxTitle')}. ${selectedCar.passengersCount}`}</span>
                                                                </div>
                                                                <div className="label-description col">
                                                                    <span className="label">{Translater.t('baggageTitle')}</span>
                                                                    <span>{`${Translater.t('maxTitle')}. ${selectedCar.baggageCount}`}</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex price">
                                                                <span>{Translater.t('passageTitle')}</span>
                                                                <span>{selectedCar.price ? `${getSymbolFromCurrency(selectedCar.currency)} ${selectedCar.price}` : Translater.t('freeTitle')}</span>
                                                            </div>
                                                            {
                                                                addedOptions && Object.keys(addedOptions).map(key => {
                                                                    let option = selectedCar.additionalOptions.find(x => x.name === key);
                                                                    let total = DEFAULT_ZERO_VALUE;

                                                                    if (option && addedOptions[key] > DEFAULT_ZERO_VALUE) {
                                                                        if (option.type === ADDITIONAL_OPTION_TYPE.VIP) {
                                                                            let count = addedOptions[key];

                                                                            if (count > option.maxClients) {
                                                                                const numberSet = parseInt(Math.ceil((count - option.maxClients) / option.extraClients), 10);
                                                                                total += option.price + numberSet * option.extraClientPrice;
                                                                            } else {
                                                                                total += option.price;
                                                                            }
                                                                        } else {
                                                                            total += option.countable ? option.price * addedOptions[key] : option.price;
                                                                        }
                                                                    }

                                                                    if (option && addedOptions[key] > DEFAULT_ZERO_VALUE) {
                                                                        return (
                                                                            <div key={`added_option_${key}`} className="d-flex price">
                                                                                <span>{`${option.name} ${option.countable ? `x${addedOptions[key]}` : STRING_EMPTY}`}</span>
                                                                                <span>{option.price ? `${getSymbolFromCurrency(selectedCar.currency)} ${total}` : Translater.t('freeTitle')}</span>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    return STRING_EMPTY;
                                                                })
                                                            }
                                                            <div className="d-flex total">
                                                                <div className='d-flex flex-column'>
                                                                    <span>{Translater.t('totalTitle')}</span>
                                                                    {returnTransfer && (<span className='return'>+ {Translater.t('returnTransfer')}</span>)}
                                                                </div>
                                                                {!oldTotal && <span>{`${getSymbolFromCurrency(selectedCar.currency)} ${parseFloat(totalPayment - discount).toFixed(2)}`}</span>}
                                                                {totalPayment - discount > oldTotal && <span style={{ color: 'red' }}>{`+ ${getSymbolFromCurrency(selectedCar.currency)} ${parseFloat((totalPayment - discount) - oldTotal).toFixed(2)}`}</span>}
                                                                {totalPayment - discount < oldTotal && <span style={{ color: 'green' }}>{`- ${getSymbolFromCurrency(selectedCar.currency)} ${parseFloat(oldTotal - (totalPayment - discount)).toFixed(2)}`}</span>}
                                                                {totalPayment - discount === oldTotal && <span>{`${getSymbolFromCurrency(selectedCar.currency)} ${parseFloat(oldTotal - (totalPayment - discount)).toFixed(2)}`}</span>}
                                                            </div>
                                                            {
                                                                promoDiscount && (
                                                                    <Tooltip title="Promo code applies only to the amount of the transfer and the return transfer (without additional options)">
                                                                        <div className="d-flex discount align-items-center">
                                                                            <div className='col p-0'>
                                                                                {Translater.t('promoDiscountTitle')}
                                                                                {
                                                                                    promoDiscount.typeOfRule === DISCOUNT_TYPE.Percentage && (
                                                                                        <span>{` (${promoDiscount.value}%)`}</span>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <div className='col p-0 text-right'>{`- ${getSymbolFromCurrency(selectedCar.currency)} ${discount}`}</div>
                                                                        </div>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        </div>
                                                    </Element>
                                                    <div className="d-flex w-100 p-0 pr-3 flex-column">
                                                        <Element name={`details_and_summary_without_additional_options`}>
                                                            <div className="d-flex flex-column w-100 mt-3 mb-3 user-details">
                                                                <span className="additional-title"><I18n t="fillDetailsTitle" /></span>
                                                                {
                                                                    (!Cookies.get('token') || checkCompanyAccountRole) && (
                                                                        <Fragment>
                                                                            <UserInput optional={false} autoFocus={true} placeholder={Translater.t('emailTitle')} containerClassName='mr-3' required={true} type={USER_INPUT_TYPES.email} onChange={setEmail} value={userEmail} />
                                                                            <UserInput optional={false} placeholder={Translater.t('phoneTitle')} required={true} type={USER_INPUT_TYPES.phone} onChange={setPhone} value={userPhone} />
                                                                            <UserInput optional={false} placeholder={Translater.t('fullNameTitle')} required={true} type={USER_INPUT_TYPES.string} onChange={setFullName} value={userFullName} />
                                                                        </Fragment>
                                                                    )
                                                                }
                                                                {(pickupLocationIsAirport || dropoffLocationIsAirport) && <UserInput optional={true} placeholder={Translater.t('flightNumberTitle')} type={USER_INPUT_TYPES.flightNumber} onChange={setFlightNumber} value={flightNumber} />}
                                                                {(pickupLocationIsAirport || dropoffLocationIsAirport) && returnTransfer && <UserInput optional={true} placeholder={Translater.t('returnFlightNumberTitle')} type={USER_INPUT_TYPES.flightNumber} onChange={setReturnFlightNumber} value={returnFlightNumber} />}
                                                                <UserInput optional={true} placeholder={Translater.t('commentTitle')} type={USER_INPUT_TYPES.string} onChange={setComment} value={userComment} />
                                                            </div>
                                                        </Element>
                                                        <Element name={`details_and_summary_with_additional_options`}>
                                                            <div className="d-flex flex-column w-100 mt-3 mb-3 user-details">
                                                                <span className="additional-title"><I18n t="additionalOptionsTitle" /></span>
                                                                <Table rowKey='name'
                                                                    showHeader={false}
                                                                    columns={columns}
                                                                    dataSource={selectedCar.additionalOptions}
                                                                    pagination={false}
                                                                    expandable={{
                                                                        expandedRowRender: ({ variants, maxCount, type, maxClients, extraClients, name: parent }) => {
                                                                            const parentAdded = addedOptions[parent];

                                                                            return type === ADDITIONAL_OPTION_TYPE.VIP ? (
                                                                                <div key={`additional_options_vip`} className="col-6 d-flex align-items-center w-100 mb-1 justify-content-start">
                                                                                    <div className='d-flex align-items-center actions-button light'>
                                                                                        {
                                                                                            !parentAdded || parentAdded === DEFAULT_ZERO_VALUE ? (
                                                                                                <Tooltip trigger={['hover', 'click']} className='disabled' title={Translater.t('minCountMsg')}>-</Tooltip>
                                                                                            ) : (<span onClick={() => removeOption(parent)}>-</span>)
                                                                                        }
                                                                                        {parentAdded || DEFAULT_ZERO_VALUE}
                                                                                        {
                                                                                            maxClients + extraClients === parentAdded ? (
                                                                                                <Tooltip trigger={['hover', 'click']} className='disabled' title={Translater.t('maxCountMsg')}>+</Tooltip>
                                                                                            ) : (<span onClick={() => addOption(parent)}>+</span>)
                                                                                        }
                                                                                    </div>
                                                                                    <span className='ml-2'>{parent}</span>
                                                                                </div>
                                                                            ) : (
                                                                                    <div className="d-flex flex-wrap w-100">
                                                                                        {
                                                                                            variants.map(({ name }, idx) => {
                                                                                                const added = addedOptions[name];

                                                                                                return (
                                                                                                    <div key={`additional_options_${idx}`} className="col-6 d-flex align-items-center w-100 mb-1 justify-content-start">
                                                                                                        <div className='d-flex align-items-center actions-button light'>
                                                                                                            {
                                                                                                                !added || added === DEFAULT_ZERO_VALUE ? (
                                                                                                                    <Tooltip trigger={['hover', 'click']} className='disabled' title={Translater.t('minCountMsg')}>-</Tooltip>
                                                                                                                ) : (<span onClick={() => removeOption(name, parent)}>-</span>)
                                                                                                            }
                                                                                                            {added}
                                                                                                            <span onClick={() => addOption(name, parent)}>+</span>
                                                                                                        </div>
                                                                                                        <span className='ml-2'>{name}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                        },
                                                                        expandedRowKeys: Object.keys(addedOptions).filter(addedOption => {
                                                                            const opt = selectedCar.additionalOptions.find(x => x.name === addedOption);

                                                                            if (opt && (opt.type === ADDITIONAL_OPTION_TYPE.VIP || (opt.variants && opt.variants.length > DEFAULT_ZERO_VALUE && addedOptions[addedOption] > DEFAULT_ZERO_VALUE))) {
                                                                                return true;
                                                                            }

                                                                            return false;
                                                                        }),
                                                                        expandIcon: () => <Fragment></Fragment>
                                                                    }} />
                                                            </div>
                                                        </Element>
                                                    </div>
                                                </div>
                                            </Element>
                                            <div className="d-flex flex-column mt-3 ml-3 mr-3">
                                                <div className="d-flex w-100 p-0 pr-3 flex-column">
                                                    <div className="d-flex flex-column w-100 mt-3 mb-3 payment">
                                                        {
                                                            !edit && (
                                                                <div id='promo-modal' className="d-flex justify-content-between align-items-center">
                                                                    <span className="title"><I18n t="choosePaymentMethodTitle" /></span>
                                                                    <span className="promo" onClick={this.togglePromoModal}>
                                                                        <I18n t="iHavePromoCodeTitle" />
                                                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M1 9L5 5L1 1" stroke="#605DF3" strokeWidth="1.5" />
                                                                        </svg>
                                                                    </span>
                                                                    {
                                                                        showPromo && (
                                                                            <Modal title="Basic Modal"
                                                                                visible={true}
                                                                                centered={true}
                                                                                footer={null}
                                                                                destroyOnClose={true}
                                                                                onCancel={this.togglePromoModal}
                                                                                getContainer={() => document.getElementById('promo-modal')}>
                                                                                <span className="additional-title"><I18n t="enterYourCodeTitle" />:</span>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <UserInput placeholder='000-000' value={promo} required={true} type={USER_INPUT_TYPES.promo} onChange={this.setPromo} />
                                                                                    <button onClick={this.setPromocode} disabled={!promo}>✓</button>
                                                                                </div>
                                                                            </Modal>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        <div className="d-flex payment-group">
                                                            <Group disabled={!paymentAvailable} defaultValue={paymentMethodToggle} onChange={(event) => { setPaymentMethod(event.target.value); this.scrollToElement(`checkout_mobile`); }}>
                                                                <div className="d-flex justify-content-between">
                                                                    <Element name={`${PAYMENT_METHODS.Checkout}_mobile`}>
                                                                        <PaymentRadio selected={paymentAvailable && paymentMethodToggle === PAYMENT_METHODS.Checkout}
                                                                            value={PAYMENT_METHODS.Checkout}
                                                                            disabled={edit ? paymentMethod !== 'Stripe' : false}
                                                                            title={
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <div className="d-flex align-items-center checkout-payments">
                                                                                        <img src={applePay} alt={PAYMENT_METHODS.Checkout} />
                                                                                        <img src={googlePay} alt={PAYMENT_METHODS.Checkout} />
                                                                                        <span className='payment-method'>
                                                                                            <I18n t="creditCardTitle" />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            }>
                                                                            <button className='continue-to-pay' onClick={() => this.beginCheckout(edit)}><I18n t="continueToPaymentTitle" /></button>
                                                                        </PaymentRadio>
                                                                    </Element>
                                                                </div>
                                                                <Element name={`${PAYMENT_METHODS.PayPal}_mobile`}>
                                                                    <PaymentRadio selected={paymentAvailable && paymentMethodToggle === PAYMENT_METHODS.PayPal}
                                                                        value={PAYMENT_METHODS.PayPal}
                                                                        disabled={edit ? paymentMethod !== 'PayPal' : false}
                                                                        title={<img src={payPal} alt={PAYMENT_METHODS.PayPal}
                                                                            additional='paypal-container' />}>
                                                                        {
                                                                            edit && oldTotal ? (
                                                                                oldTotal < totalPayment - discount ? (
                                                                                    <PayPalButton options={{
                                                                                        clientId: config.paypalKey,
                                                                                        currency: selectedCar.currency
                                                                                    }}
                                                                                        style={{
                                                                                            layout: 'horizontal',
                                                                                            color: 'black',
                                                                                            shape: 'rect',
                                                                                            label: 'paypal',
                                                                                            tagline: false
                                                                                        }}
                                                                                        createOrder={() => editCheckoutPaypal()}
                                                                                        onApprove={(details) => paypalCheckoutSessionCompleted(details.orderID)}
                                                                                        onError={(err) => paypalFailStatus(err)} />
                                                                                ) : (
                                                                                        <button className='continue-to-pay' onClick={() => this.beginCheckout(edit)}><I18n t="continueToPaymentTitle" /></button>
                                                                                    )
                                                                            ) : (
                                                                                    <PayPalButton options={{
                                                                                        clientId: config.paypalKey,
                                                                                        currency: selectedCar.currency
                                                                                    }}
                                                                                        style={{
                                                                                            layout: 'horizontal',
                                                                                            color: 'black',
                                                                                            shape: 'rect',
                                                                                            label: 'paypal',
                                                                                            tagline: false
                                                                                        }}
                                                                                        createOrder={() => paypalCheckout(userEmailFromCoockies)}
                                                                                        onApprove={(details) => paypalCheckoutSessionCompleted(details.orderID)}
                                                                                        onError={(err) => paypalFailStatus(err)} />
                                                                                )
                                                                        }
                                                                    </PaymentRadio>
                                                                </Element>
                                                                <Element name={`${PAYMENT_METHODS.Invoice}_mobile`}>
                                                                    <PaymentRadio selected={paymentAvailable && paymentMethodToggle === PAYMENT_METHODS.Invoice}
                                                                        value={PAYMENT_METHODS.Invoice}
                                                                        disabled={edit ? paymentMethod !== null : false}
                                                                        title={
                                                                            <span className='payment-method'>
                                                                                <I18n t="invoiceTitle" />
                                                                                <span className="subtitle">
                                                                                    (<I18n t="onlyRegisteredUsersMsg" />)
                                                                                </span>
                                                                            </span>
                                                                        }>
                                                                        <Invoice handleSubmit={() => edit ? this.beginCheckout(edit) : invoicePayment()}
                                                                            userEmail={invoiceEmail}
                                                                            userPassword={invoicePassword}
                                                                            setPassword={setInvoicePassword}
                                                                            setEmail={setInvoiceEmail} />
                                                                    </PaymentRadio>
                                                                </Element>
                                                            </Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                }
                                }
                            </ScriptLoader>
                        </LoadingOverlay>
                    )
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { location, i18n: { locale } } = state;

    return {
        ...location,
        locale
    };
}

const mapDispatchToProps = {
    setEmail: (value) => homeActions.setValue(PROP_KEYS.userEmail, value),
    setPassword: (value) => homeActions.setValue(PROP_KEYS.userPassword, value),
    setPhone: (value) => homeActions.setValue(PROP_KEYS.userPhone, value),
    setFullName: (value) => homeActions.setValue(PROP_KEYS.userFullName, value),
    setComment: (value) => homeActions.setValue(PROP_KEYS.userComment, value),
    setPromocode: (value) => homeActions.setValue(PROP_KEYS.promocode, value),
    setPaymentMethod: (value) => homeActions.setValue(PROP_KEYS.paymentMethodToggle, value),
    setPromo: (value) => homeActions.setValue(PROP_KEYS.promo, value),
    setShowPromo: (value) => homeActions.setValue(PROP_KEYS.showPromo, value),
    addOption: (value, parent) => homeActions.addOption(value, parent),
    removeOption: (value, parent, all) => homeActions.removeOption(value, parent, all),
    removeVipOption: (value) => homeActions.removeOption(value, null, true),
    beginCheckout: (edit, userEmailFromCoockies) => homeActions.beginCheckout(edit, userEmailFromCoockies),
    paypalCheckout: (userEmailFromCoockies) => homeActions.createCheckoutPaypal(userEmailFromCoockies),
    paypalCheckoutSessionCompleted: (orderID) => homeActions.paypalCheckoutSessionCompleted(orderID),
    paypalCancelStatus: (value) => homeActions.paypalCancelStatus(value),
    paypalFailStatus: (value) => homeActions.paypalFailStatus(value),
    invoicePayment: () => homeActions.invoicePayment(),
    checkPromocode: (value) => homeActions.checkPromocode(value),
    editCheckoutPaypal: () => homeActions.editCheckoutPaypal(),
    setFlightNumber: (value) => homeActions.setValue(PROP_KEYS.flightNumber, value),
    setReturnFlightNumber: (value) => homeActions.setValue(PROP_KEYS.returnFlightNumber, value),
    setInvoiceEmail: (value) => homeActions.setValue(PROP_KEYS.invoiceEmail, value),
    setInvoicePassword: (value) => homeActions.setValue(PROP_KEYS.invoicePassword, value),
}

const progressHomeCheckout = withRouter(connect(mapStateToProps, mapDispatchToProps)(withLayout(HomeCheckoutMobile, 'results_page')));
export { progressHomeCheckout as HomeCheckoutMobile };